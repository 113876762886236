
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.patents__content {
  max-width: 72rem;
  margin: auto;

  @include mq($until: m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }
}
