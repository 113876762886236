
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































$bp: l;

.landing {
  ::v-deep {
    .hero {
      padding-bottom: 4rem;

      [class*='action--'] {
        background-color: $c-dark-blue;

        .action__label {
          color: $c-white;
        }

        svg {
          path {
            fill: $c-white;
          }
        }
      }
    }

    .duo-horizontal-wrapper,
    .duo-picture-wrapper {
      @include mq($bp) {
        flex-direction: row;
        align-items: flex-start;
        gap: 16rem;

        > div {
          flex: 1;
          padding: 0;
        }
      }
    }

    .duo-horizontal__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 20px, xxl: 28px));
      @include fluid(
        line-height,
        (
          xxs: 28px,
          xxl: 38px,
        )
      );

      padding-left: 0;
      color: $c-black;
      font-weight: 500;
    }

    .flexible-picture {
      width: 100%;
      max-width: $content-width-max;
      padding-right: 2.2rem;
      padding-left: 2.2rem;

      @include mq(m) {
        padding-right: 8rem;
        padding-left: 8rem;
      }

      @include mq(wrapper) {
        margin-right: auto;
        margin-left: auto;
      }
    }

    .flexible-picture-outer {
      @include aspect-ratio(128, 42);
    }
  }
}
